// Angular
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
// Fuse
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
// Loader
import { NgxUiLoaderService } from 'ngx-ui-loader';
// Translate
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';
// Environment
import { environment } from '../../../../environments/environment';
import { DashboardService } from '../../../main/dashboard/dashboard.service';

@Component({
    selector     : 'quick-panel',
    templateUrl  : './quick-panel.component.html',
    styleUrls    : ['./quick-panel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class QuickPanelComponent implements OnInit {
    // Data bindings
    date: Date;
    settings: any;
    complex = {
        id: '',
    };
  supplierId: string | null;
  data = [];
  unreadNotifications = 0;
  role = '';
  url = new URL(environment.mercure);

    /**
     * Constructor
     */
    constructor(
        private dashboardService: DashboardService,
        private fuseTranslationLoaderService: FuseTranslationLoaderService,
        private loader: NgxUiLoaderService,
        private router: Router,
        private translate: TranslateService,
    )
    {
        // Set the defaults
        this.date = new Date();
        this.settings = {
            notify: true,
            cloud : false,
            retro : true
        };
        this.fuseTranslationLoaderService.loadTranslations(english, spanish);
        this.translate.use(environment.DEFAULT_LANG);
        this.url.searchParams.append('topic', 'notification');
    }

    ngOnInit(): void {
        const lang = localStorage.getItem('lang');
        const token = localStorage.getItem('token');
        const role = localStorage.getItem('role');
        this.supplierId = localStorage.getItem('supplier_id');
        if (lang !== undefined && lang !== '' && lang !== null) {
            this.translate.use(lang);
        } else {
            this.translate.use(this.translate.currentLang);
        }
        console.log(role);
        if (localStorage.getItem('name') !== '' && localStorage.getItem('name') !== null &&
          localStorage.getItem('name') !== undefined) {
        // this.notifications();
      }
    }

  notifications(): void {
    this.dashboardService.getNotifications({
      supplier_id: this.supplierId,
      lang: this.translate.currentLang,
    }).subscribe(
      result => {
        if (result.success === true) {
          this.unreadNotifications = result.data.unreadNotifications;
          this.data = result.data.notifications;
        }
      }
    );
  }

  readNotification(id: any, link: any, linkId: any): void {
    this.dashboardService.setReadNotification({
      notification_id: id,
    }).subscribe(
      result => {
        if (result.success === true) {
          this.notifications();
         // this.router.navigate([link, linkId]);
        }
      },
    );
  }

  cleanNotifications(): void {
    this.dashboardService.cleanNotifications({
      supplier_id: this.supplierId,
    }).subscribe(
      result => {
        if (result.success === true) {
          // this.notifications();
        }
      },
    );
  }
}
