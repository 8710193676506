export const locale = {
  lang: 'es',
  data: {
    'PANEL': {
      'NOTIFICATIONS': 'Notificaciones',
      'EMPTY_DATA': 'No hay notificaciones para mostrar',
      'MARK_ALL_READ': 'Marcar todas las notificaciones como leídas',
      'REMOVE_READ_NOTIFICATIONS': 'Limpiar',
    },
  }
};
