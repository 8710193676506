<div fxLayout="column" fxFlex fusePerfectScrollbar>
  <mat-list class="date" cdkFocusRegionStart>
    <div class="secondary-text mat-display-1 mb-0 p-16">
      <div fxLayout="row" fxLayoutAlign="start start">
        <span>{{ date | date: 'mediumDate' }}</span>
      </div>
    </div>
  </mat-list>
  <mat-divider class="notification-divider mt-4"></mat-divider>
  <cdk-virtual-scroll-viewport itemSize="5" fxFlex>
    <div fxLayout="row" fxLayoutAlign="start">
      <span class="secondary-text mt-4 ml-12">{{ 'PANEL.NOTIFICATIONS'|translate }}</span>
      <button color="primary" mat-raised-button class="mt-8 ml-12"
              (click)="cleanNotifications()"
              *ngIf="data.length > 0">
        {{ 'PANEL.REMOVE_READ_NOTIFICATIONS'|translate }}
      </button>
    </div>
    <mat-divider class="notification-divider mt-4"></mat-divider>
    <span class='p-12 secondary-text' *ngIf='data.length === 0'>
          {{ 'PANEL.EMPTY_DATA'|translate }}
        </span>

    <div fxLayout="column"  *ngIf="data.length > 0">
      <div *cdkVirtualFor="let notification of data">
        <div fxLayout="row"
             class="notification-click mt-12"
             (click)="readNotification(notification.id, notification.link, notification.linkId); notifications()">
          <div fxLayout="column">
            <div fxFlex="80%">
              <div fxLayout="row wrap" class="mt-5" fxLayoutAlign="start start" fxLayoutGap="16px grid">
                <div fxFlex="20%" fxLayoutAlign="center">
                  <mat-icon class="notification-icon">{{ notification.icon }}</mat-icon>
                </div>
                <div fxFlex="80%">
                  <p>{{ notification.descriptionEs }}</p>
                </div>
              </div>
            </div>
            <div fxFlex="20%">
              <div fxLayout="row wrap" class="mt-5" fxLayoutAlign="start start" fxLayoutGap="16px grid">
                <div fxFlex="10%" fxLayoutAlign="start end">
                  <mat-icon class="new-icon" *ngIf="notification.read === false">error</mat-icon>
                </div>
                <div fxFlex="90%" fxLayoutAlign="end">
                  <span class="time-elapsed">{{ notification.timeElapsed }}</span>
                </div>
              </div>
            </div>
            <mat-divider class="notification-divider mt-4"></mat-divider>
          </div>
        </div>
      </div>
    </div>
  </cdk-virtual-scroll-viewport>
</div>