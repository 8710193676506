export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',
            'SAMPLE': {
                'TITLE': 'Dashboard',
            },
          'REQUIREMENT': {
            'TITLE': 'Requirements',
          },
          'MAINTENANCES': {
            'TITLE': 'Maintenances',
          },
          'MAINTENANCE': {
            'TITLE': 'Maintenances',
          },
          'PROFILE': {
            'TITLE': 'Profile',
          },
          'SUPPLIER': {
            'TITLE': 'Supplier',
          },
          'DASHBOARD': {
            'TITLE': 'Home',
          },
          'EQUIPMENT': {
            'TITLE': 'Equipment',
          },
        }
    }
};
