import { FuseNavigation } from '@fuse/types';

export const supplierNavigation: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : '',
        translate: '',
        type     : 'group',
        children : [
          {
            id: 'dashboard',
            title: 'Dashboard',
            translate: 'NAV.DASHBOARD.TITLE',
            type: 'item',
            icon: 'home',
            url: '/dashboard/main',
          },
          {
            id: 'requirement',
            title: 'Requirement',
            translate: 'NAV.REQUIREMENT.TITLE',
            type: 'item',
            icon: 'business_center',
            url: '/requirement/main',
          },
          {
            id: 'maintenance',
            title: 'Maintenance',
            translate: 'NAV.MAINTENANCE.TITLE',
            type: 'item',
            icon: 'build',
            url: '/maintenance/main',
          },
          {
            id: 'profile',
            title: 'Profile',
            translate: 'NAV.PROFILE.TITLE',
            type: 'item',
            icon: 'account_circle',
            url: '/profile/main',
          },
        ],
    }
];
