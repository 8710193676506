// Angular
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
// Translate
import {TranslateModule} from '@ngx-translate/core';
// Fuse
import {FuseSharedModule} from '@fuse/shared.module';
// Local component
import {SampleComponent} from './sample.component';

const routes = [
    {
        path: 'sample',
        component: SampleComponent
    }
];

@NgModule({
    declarations: [
        SampleComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        TranslateModule,

        FuseSharedModule
    ],
    exports: [
        SampleComponent
    ]
})

export class SampleModule {
}
