// Angular
import { Injectable } from '@angular/core';
// Angular material
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
// Local components
import { Constants } from './constants';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(private snackBar: MatSnackBar) {}
  // tslint:disable-next-line:typedef
  public showToast(type, message) {
    this.snackBar.open(message, Constants.TOAST_ALERT, {
      duration: 10000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: type === Constants.TOAST_TYPE_ERROR ? ['toast-error'] : ['toast-success'],
    });
  }
}
