<h1 matDialogTitle>{{ 'CONFIRM.CONFIRM'|translate }}</h1>
<div mat-dialog-content>{{ confirmMessage }}</div>
<div mat-dialog-actions class="pt-24">
  <button mat-raised-button color="primary" class="mr-16"
          (click)="dialogRef.close(true)">
    {{ 'CONFIRM.CONFIRM'|translate }}
  </button>
  <button mat-raised-button color="accent"
          (click)="dialogRef.close(false)">
    {{ 'CONFIRM.CANCEL'|translate }}
  </button>
</div>

