// Angular
import { Component, ViewEncapsulation } from '@angular/core';
// Material
import { MatDialogRef } from '@angular/material/dialog';
// Fuse
import { fuseAnimations } from '../../animations';
// Translate
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';

@Component({
    selector   : 'fuse-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls  : ['./confirm-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class FuseConfirmDialogComponent {
  public confirmMessage: string;

  constructor(
    private fuseTranslationLoaderService: FuseTranslationLoaderService,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<FuseConfirmDialogComponent>,
  )
  {
    this.fuseTranslationLoaderService.loadTranslations(english, spanish);
    const lang = localStorage.getItem('lang');
    if (lang !== undefined && lang !== '' && lang !== null) {
      this.translate.use(lang);
    } else {
      this.translate.use(this.translate.currentLang);
    }
  }

}
