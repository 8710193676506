import { FuseNavigation } from '@fuse/types';

export const adminNavigation: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : '',
        translate: '',
        type     : 'group',
        children : [
          {
            id: 'dashboard',
            title: 'Dashboard',
            translate: 'NAV.DASHBOARD.TITLE',
            type: 'item',
            icon: 'home',
            url: '/dashboard/main',
          },
              {
                id: 'equipment',
                title: 'Equipment',
                translate: 'NAV.EQUIPMENT.TITLE',
                type: 'item',
                icon: 'format_paint',
                url: '/equipment/main',
              },
              {
            id: 'supplier',
            title: 'Supplier',
            translate: 'NAV.SUPPLIER.TITLE',
            type: 'item',
            icon: 'work_outline',
            url: '/supplier/main',
              },
              {
                id: 'requirement',
                title: 'Requirement',
                translate: 'NAV.REQUIREMENT.TITLE',
                type: 'item',
                icon: 'business_center',
                url: '/requirementAdmin/main',
              },
              {
                id: 'maintenance',
                title: 'Maintenance',
                translate: 'NAV.MAINTENANCE.TITLE',
                type: 'item',
                icon: 'build',
                url: '/maintenanceAdmin/main',
              },
        ],
    }
];
