import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private complexSource = new Subject<any>();
  complex = this.complexSource.asObservable();

  getComplex(data: any): void {
    this.complexSource.next(data);
  }
}
