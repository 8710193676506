// Angular
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
// Fuse
import { FuseSharedModule } from '@fuse/shared.module';
// Translate
import { TranslateModule } from '@ngx-translate/core';
// Material
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
// Local Component
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ComplexSelectorComponent } from './complex-selector/complex-selector.component';

@NgModule({
  declarations: [
    ComplexSelectorComponent,
    BreadcrumbComponent,
  ],
  imports: [
// Fuse
    FuseSharedModule,
    // Material
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    // Translate
    TranslateModule,
    RouterModule,
  ],
  exports: [
    ComplexSelectorComponent,
    BreadcrumbComponent,
    ],
})
export class CommonComponentModule { }
