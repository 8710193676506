export const environment = {
    DEFAULT_LANG: 'es',
    production: false,
    apiUrl: 'https://admin.beco.tools/api/v1/supplier/',
    // apiUrl: 'http://localhost:8000/api/v1/supplier/',
    loginApiUrl2: 'https://admin.beco.tools/api/supplier/',
    loginApiUrl: 'https://admin.beco.tools/api/supplier/',
    //loginApiUrl: 'https://beco-admin.paratesting.com/api/supplier/',
    // loginApiUrl: 'http://localhost:8000/api/supplier/',
    mercure: 'https://admin.beco.tools/hub/.well-known/mercure',
    apiUrl2: 'https://admin.beco.tools/api/v1/finance/',
    // apiUrl2: 'http://localhost:8000/api/v1/finance/',
    apiUrl3: 'https://admin.beco.tools/api/v1/cp/',
    // apiUrl3: 'https://beco-admin.paratesting.com/api/v1/cp/',
    // apiUrl3: 'http://localhost:8000/api/v1/cp/',
};
