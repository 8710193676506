import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : 'Applications',
        translate: 'NAV.APPLICATIONS',
        type     : 'group',
        children : [
            {
                id       : 'sample',
                title    : 'Sample',
                translate: 'NAV.SAMPLE.TITLE',
                type     : 'item',
                icon     : 'email',
                url      : '/sample',
            },
          {
            id: 'dashboard',
            title: 'Dashboard',
            translate: 'NAV.DASHBOARD.TITLE',
            type: 'item',
            icon: 'home',
            url: '/dashboard/main',
          },
          {
            id: 'requirement',
            title: 'Requirement',
            translate: 'NAV.REQUIREMENT.TITLE',
            type: 'item',
            icon: 'business_center',
            url: '/requirement/main',
          },
          {
            id: 'maintenance',
            title: 'Maintenance',
            translate: 'NAV.MAINTENANCE.TITLE',
            type: 'item',
            icon: 'build',
            url: '/maintenance/main',
          },
          {
            id: 'maintenances',
            title: 'Catalogue',
            translate: 'NAV.MAINTENANCES.TITLE',
            type: 'collapsable',
            icon: 'settings',
            children: [
              {
                id: 'equipment',
                title: 'Equipment',
                translate: 'NAV.EQUIPMENT.TITLE',
                type: 'item',
                icon: 'format_paint',
                url: '/equipment/main',
              },
              {
                id: 'requirement',
                title: 'Requirement',
                translate: 'NAV.REQUIREMENT.TITLE',
                type: 'item',
                icon: 'business_center',
                url: '/requirementAdmin/main',
              },
              {
                id: 'maintenance',
                title: 'Maintenance',
                translate: 'NAV.MAINTENANCE.TITLE',
                type: 'item',
                icon: 'build',
                url: '/maintenanceAdmin/main',
              },
              {
                id: 'supplier',
                title: 'Supplier',
                translate: 'NAV.SUPPLIER.TITLE',
                type: 'item',
                icon: 'work_outline',
                url: '/supplier/main',
              },
            ],
          },
          {
            id: 'profile',
            title: 'Profile',
            translate: 'NAV.PROFILE.TITLE',
            type: 'item',
            icon: 'account_circle',
            url: '/profile/main',
          },
        ],
    }
];
