import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ReplaceText'
})
export class NullEmptyReplaceTextPipe implements PipeTransform {

  transform(value: any, replaceText: string = '-'): any {
    if (typeof value === 'undefined' || value === null || value === '') {
      return replaceText;
    }
    return value;
  }

}
