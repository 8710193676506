export const locale = {
    lang: 'es',
    data: {
        'NAV': {
            'APPLICATIONS': 'Aplicaciones',
            'SAMPLE': {
                'TITLE': 'Inicio',
            },
          'REQUIREMENT': {
            'TITLE': 'Requerimientos',
          },
          'MAINTENANCES': {
            'TITLE': 'Mantenimientos',
          },
          'MAINTENANCE': {
            'TITLE': 'Mantenimientos',
          },
          'PROFILE': {
            'TITLE': 'Perfil',
          },
          'SUPPLIER': {
            'TITLE': 'Proveedor',
          },
          'DASHBOARD': {
            'TITLE': 'Inicio',
          },
          'EQUIPMENT': {
            'TITLE': 'Equipo',
          },
        }
    }
};
