// Angular
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
// Translate
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';
// Fuse
import { FuseConfigService } from '@fuse/services/config.service';
import { environment } from 'environments/environment';
import { fuseAnimations } from '@fuse/animations';
// Loader
import { NgxUiLoaderService } from 'ngx-ui-loader';
// Local services
import { ToastService } from '@fuse/utils/toast.service';
import { ComplexService } from '../complex.service';
import { DataService } from '../../../data.service';

@Component({
  selector: 'complex-selector',
  templateUrl: './complex-selector.component.html',
  styleUrls: ['./complex-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class ComplexSelectorComponent implements OnInit {
  // data
  @Output() complexConn = new EventEmitter<any>();
  // Reactive form
  complexSelectorForm: FormGroup;
  // Data bindings
  complexes = [];
  complex = {
    address: '',
    country: '',
    id: '',
    name: '',
    state: '',
    phone: '',
    city: '',
    currency: ''
  };
  complexSession = '';

  constructor(
    private complexService: ComplexService,
    private dataService: DataService,
    private formBuilder: FormBuilder,
    private fuseConfigService: FuseConfigService,
    private fuseTranslationLoaderService: FuseTranslationLoaderService,
    private loader: NgxUiLoaderService,
    private router: Router,
    private toast: ToastService,
    private translate: TranslateService,
  ) {
    this.fuseTranslationLoaderService.loadTranslations(english, spanish);
    this.translate.use(environment.DEFAULT_LANG);
  }

  ngOnInit(): void {
    this.complexSession = localStorage.getItem('complex_session');
    const lang = localStorage.getItem('lang');
    if (lang !== undefined && lang !== '' && lang !== null) {
      this.translate.use(lang);
    } else {
      this.translate.use(this.translate.currentLang);
    }
    this.complexSelectorForm = this.formBuilder.group({
      complex: ['', [Validators.required]],
    });
    this.getComplexes();
  }

  getComplexes(): void {
    this.loader.start();
    this.complexService.getComplexes()
      .subscribe(result => {
        if (result.success === true) {
          this.complexes = result.data;
          if (this.complexes.length > 0) {
            const response = this.verifyComplexSession();
            if (response) {
              this.complexSelectorForm.controls.complex.setValue(
                response,
              );
              this.complex.id = response.id;
              this.complex.country = response.country;
              this.complex.city = response.city;
              this.complex.address = response.address;
              this.complex.phone = response.phone;
              this.complex.name = response.name;
              this.complex.currency = response.defaultCurrencySymbol;
              // Send complex id to siblings components
              this.complexConn.emit(this.complex.id.toString());
            } else  {
              this.complexSelectorForm.controls.complex.setValue(
                this.complexes[0],
              );
              this.complex.id = this.complexes[0].id;
              this.complex.country = this.complexes[0].country;
              this.complex.city = this.complexes[0].city;
              this.complex.address = this.complexes[0].address;
              this.complex.phone = this.complexes[0].phone;
              this.complex.name = this.complexes[0].name;
              this.complex.currency = this.complexes[0].defaultCurrencySymbol;
              localStorage.setItem('complex_currency', this.complexes[0].defaultCurrencySymbol);
              localStorage.setItem('complex_currency_iso', this.complexes[0].defaultCurrencyIso);
              // Send complex id to siblings components
              this.complexConn.emit(this.complex.id.toString());
            }
          }
          this.dataService.getComplex(this.complex);
          this.loader.stop();
        }
      }, () => {
        this.router.navigate(['/pages/error/']);
        this.loader.stop();
      });
  }

  changeCurrentComplex(complex: any): void {
    localStorage.setItem('complex_session', complex.value.id);
    localStorage.setItem('complex_currency', complex.value.defaultCurrencySymbol);
    localStorage.setItem('complex_currency_iso', complex.value.defaultCurrencyIso);
    this.complex.id = complex.value.id;
    this.complex.name = complex.value.name;
    this.complex.country = complex.value.country;
    this.complex.city = complex.value.city;
    this.complex.address = complex.value.address;
    this.complex.phone = complex.value.phone;
    this.complex.currency = complex.value.defaultCurrencySymbol;
    // Send complex id to siblings components
    this.complexConn.emit(this.complex.id.toString());
    this.dataService.getComplex(this.complex);
  }

  private verifyComplexSession(): any {
    if (this.complexSession !== null
      && this.complexSession  !== undefined
      && this.complexSession  !== '') {
      return  this.complexes.find(c => c.id === +this.complexSession);
    }
  }

}
