// Angular Modules
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
// Angular Material
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
// Translate
import { TranslateModule } from '@ngx-translate/core';
// Angular Fuse
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import {fuseConfig} from 'app/fuse-config';
// jwt
import { POSITION, SPINNER, PB_DIRECTION, NgxUiLoaderModule, NgxUiLoaderConfig } from 'ngx-ui-loader';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
// Guards
import { AuthGuardService } from './main/oauth/auth-guard.service';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsColor: '#FFFFFF',
  fgsPosition: POSITION.centerCenter,
  fgsSize: 50,
  fgsType: SPINNER.foldingCube,
  pbDirection: PB_DIRECTION.leftToRight,
  pbThickness: 5,
  pbColor: '#FFFFFF',
  logoPosition: 'top-center',
};

const appRoutes: Routes = [
  {
    path: 'oauth',
    loadChildren: () => import('./main/oauth/oauth.module').then((m) => m.OauthModule),
  },
  {
    path: 'pages',
    loadChildren: () => import('./main/pages/pages.module').then((m) => m.PagesModule),
  },
  {
    path: 'requirement',
    loadChildren: () => import('./main/requirement/requirement.module').then((m) => m.RequirementModule),
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./main/maintenance/maintenance.module').then((m) => m.MaintenanceModule),
  },
  {
    path: 'profile',
    loadChildren: () => import('./main/profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'supplier',
    loadChildren: () => import('./main/supplier/supplier.module').then((m) => m.SupplierModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./main/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'equipment',
    loadChildren: () => import('./main/equipment/equipment.module').then((m) => m.EquipmentModule),
  },
  {
    path: 'requirementAdmin',
    loadChildren: () => import('./main/requirement-admin/requirement-admin.module').then((m) => m.RequirementAdminModule),
  },
  {
    path: 'maintenanceAdmin',
    loadChildren: () => import('./main/maintenance-admin/maintenance-admin.module').then((m) => m.MaintenanceAdminModule),
  },
  {
    path: 'sample',
    loadChildren: () => import('./main/oauth/oauth.module').then((m) => m.OauthModule),
  },
  {
    path: '**',
    redirectTo: 'oauth/login'
  }
];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {relativeLinkResolution: 'legacy'}),
    TranslateModule.forRoot(),
    // Material moment date module
    MatMomentDateModule,
    // Material
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,
    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
    // Loader
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    // App modules
    LayoutModule,
    SampleModule
  ],
  bootstrap: [
    AppComponent
  ],
  providers: [
    AuthGuardService,
    JwtHelperService,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
})
export class AppModule {
}
